"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_GAS_ALPH_AMOUNT = exports.DEFAULT_GAS_ATTOALPH_AMOUNT = exports.DEFAULT_GAS_PRICE = exports.DEFAULT_GAS_AMOUNT = exports.ZERO_ADDRESS = exports.DUST_AMOUNT = exports.ONE_ALPH = exports.ALPH_TOKEN_ID = exports.MIN_UTXO_SET_AMOUNT = exports.TOTAL_NUMBER_OF_CHAINS = exports.TOTAL_NUMBER_OF_GROUPS = void 0;
exports.TOTAL_NUMBER_OF_GROUPS = 4;
exports.TOTAL_NUMBER_OF_CHAINS = exports.TOTAL_NUMBER_OF_GROUPS * exports.TOTAL_NUMBER_OF_GROUPS;
exports.MIN_UTXO_SET_AMOUNT = BigInt(1000000000000);
exports.ALPH_TOKEN_ID = ''.padStart(64, '0');
exports.ONE_ALPH = 10n ** 18n;
exports.DUST_AMOUNT = 10n ** 15n;
exports.ZERO_ADDRESS = 'tgx7VNFoP9DJiFMFgXXtafQZkUvyEdDHT9ryamHJYrjq';
exports.DEFAULT_GAS_AMOUNT = 20000;
exports.DEFAULT_GAS_PRICE = 10n ** 11n;
exports.DEFAULT_GAS_ATTOALPH_AMOUNT = BigInt(exports.DEFAULT_GAS_AMOUNT) * exports.DEFAULT_GAS_PRICE;
exports.DEFAULT_GAS_ALPH_AMOUNT = 0.002;
